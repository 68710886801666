<template>
  <HandleDataForms v-bind:apiSource="mongo" v-bind:dataSource="'/' + database" title="Orientadores" collection="eventos_orientadores" templateTable="TableOrientadoresForm" templateTableModal="TableOrientadoresModal" v-bind:dataExcel="dataExcel" />
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import moment from 'moment'
import HandleDataForms from '@/components/HandleDataForms.vue'

export default {
  components: {
    HandleDataForms,
  },
  setup() {
    const store = useStore()
    const mongo = computed(() => store.getters.getMongoURL)
    const database = computed(() => store.getters.getDBDashboard)

    const startDate = computed(() =>
      moment()
        .startOf('week')
        .format('YYYY-MM-DD')
    )
    const endDate = computed(() => moment().format('YYYY-MM-DD'))

    let dataExcel = {
      fields: {
        createdAt: 'Fecha',
        id_evento: 'Id evento',
        nombre_evento: 'Nombre evento',
        formulario: 'formulario',
        tipo_documento: 'Tipo documento',
        numero_documento: 'Número de documento',
        nombre: 'Nombre',
        apellido_paterno: 'Apellido Paterno',
        email: 'E-mail',
        codigo_area: 'Código de area',
        telefono: 'Celular',
        cargo: 'Cargo',
        cuartos_medios: 'Cuartos Medios',
        cantidad_alumnos: 'N° alumnos',
        region_nombre: 'Region',
        comuna_nombre: 'Comuna',
        colegio_nombre: 'Colegio',
        utm_source: 'Fuente UTM',
        utm_medium: 'Medio UTM',
        utm_campaign: 'Campaña UTM',
        device: 'dispositivo',
      },
      query: {
        collection: 'Orientadores',
        date_field: 'createdAt',
        fecha_inicio: startDate.value + ' 00:00:00',
        fecha_fin: endDate.value + ' 23:59:59',
      },
    }

    return {
      mongo,
      dataExcel,
      database
    }
  },
}
</script>
